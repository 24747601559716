import React, { FC, useEffect, useState } from 'react';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { getResourceText } from '../lib/language';
import Loading, { LoadingSpinner } from './loading';
import { formatForDownloadGoogleDriveURL } from '../lib/url';

const IntersectableDownloadable: FC<IntersectableProps> = ({ url, interactive, type }) => {
  const [ref, entry] = useIntersectionObserver<HTMLDivElement>({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded && entry?.isIntersecting) {
      setIsLoaded(true);
    }
  }, [entry?.isIntersecting]);

  return (
    <div className="downloadable-resource-container" ref={ref}>
      {!interactive ? <div className="downloadable-resource-container__downloadable-resource-overlay" /> : null}
      {type === 'image' ? (
        isLoaded ? (
          <img
            src={url}
            className="downloadable-resource-container__downloadable-resource"
            alt={`Downloadable resource: ${url}`}
            title={`Downloadable resource: ${url}`}
          />
        ) : null
      ) : isLoaded ? (
        <iframe
          src={url}
          className="downloadable-resource-container__downloadable-resource"
          title={`Downloadable resource: ${url}`}
        />
      ) : null}
      <Loading css="downloadable-resource-container__loading">
        <LoadingSpinner />
      </Loading>
    </div>
  );
};

const DownloadableView: FC<DownloadableViewProps> = ({ googleDocsURL, interactive = false, type = 'pdf' }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (googleDocsURL) {
      setUrl(formatForDownloadGoogleDriveURL(googleDocsURL));
    }
  }, [googleDocsURL]);

  return url ? (
    <IntersectableDownloadable url={url} interactive={interactive} type={type} />
  ) : (
    <div className="downloadable-resource-container downloadable-resource-container--no-downloadable-resource error">
      {getResourceText('noDownloadableResource')}
    </div>
  );
};

interface DownloadableViewProps {
  googleDocsURL: string;
  interactive?: boolean;
  type?: DownloadableType;
}

type DownloadableType = 'pdf' | 'image';

type IntersectableProps = {
  url: string;
  type: DownloadableType;
  interactive: boolean;
};

export default DownloadableView;
